export const MAX_LENGTH = 300;

export const CONST_VARIABLE = {
  NAME: 'NAME',
  COMPANY: 'COMPANY',
  DESCRIPTION: 'DESCRIPTION',
  PERMISSION: 'PERMISSION',
};

export const READ_PERMISSION_LIST = [
  'dispatcher.orders.read',
  'dispatcher.partners.read',
  'dispatcher.reports.read',
  'dispatcher.manage.read',
  'dispatcher.items.read',
  'dispatcher.explore.read',
  'dispatcher.network.read',
  'dispatcher.invoices.read',
  'dispatcher.create_orders.read',
  'dispatcher.dashboard.read',
  'dispatcher.notifications.read',
];

export const WRITE_PERMISSION_LIST = [
  'dispatcher.orders.write',
  'dispatcher.partners.write',
  'dispatcher.reports.write',
  'dispatcher.network.write',
  'dispatcher.items.write',
  'dispatcher.explore.write',
  'dispatcher.manage.write',
  'dispatcher.invoices.write',
  'dispatcher.create_orders.write',
  'dispatcher.dashboard.write',
  'dispatcher.notifications.write',
];

export const MUST_HAVE_READ_PERMISSION_LIST = [];
export const DISABLE_WRITE_PERMISSION_LIST = [
  'dispatcher.reports.write',
  'dispatcher.dashboard.write',
  'dispatcher.notifications.write',
];
